export const skills = [
  {
    name: "HTML",
    image: "html_5.svg",
    experience: true,
    class: "",
  },
  {
    name: "Bootstrap",
    image: "bootstrap-5.svg",
    experience: true,
    class: "",
  },
  {
    name: "CSS",
    image: "css_3.svg",
    experience: true,
    class: "",
  },
  {
    name: "SASS",
    image: "sass-1.svg",
    experience: true,
    class: "",
  },
  {
    name: "jQuery",
    image: "jquery.svg",
    experience: true,
    class: "white",
  },
  {
    name: "React",
    image: "react.svg",
    experience: true,
    class: "",
  },
  {
    name: "Next.js",
    image: "next-js.svg",
    experience: true,
    class: "white",
  },
  {
    name: "Redux",
    image: "redux.svg",
    experience: true,
    class: "",
  },
  {
    name: "Gatsby",
    image: "gatsby.svg",
    experience: true,
    class: "",
  },
  {
    name: "TailwindCSS",
    image: "tailwindcss.svg",
    experience: true,
    class: "",
  },
  {
    name: "PHP",
    image: "php.svg",
    experience: true,
    class: "",
  },
  {
    name: "WordPress",
    image: "wordpress.svg",
    experience: true,
    class: "",
  },
  {
    name: "Strapi",
    image: "strapi-2.svg",
    experience: true,
    class: "",
  },
  {
    name: "Figma",
    image: "figma.svg",
    experience: false,
    class: "",
  },
  {
    name: "Photoshop",
    image: "ph.svg",
    experience: false,
    class: "",
  },
  {
    name: "Zeplin",
    image: "zeplin.svg",
    experience: false,
    class: "",
  },
  {
    name: "Vercel",
    image: "vercel.svg",
    experience: false,
    class: "white",
  },
  {
    name: "AWS",
    image: "aws.svg",
    experience: false,
    class: "",
  },
  {
    name: "Netlify",
    image: "netlify.svg",
    experience: false,
    class: "",
  },
  {
    name: "DigitalOcean",
    image: "digitalocean.svg",
    experience: false,
    class: "",
  },
  {
    name: "Firebase",
    image: "firebase-1.svg",
    experience: false,
    class: "",
  },
  {
    name: "GitHub",
    image: "github-icon-1.svg",
    experience: false,
    class: "white",
  },
  {
    name: "MySQL",
    image: "mysql-6.svg",
    experience: true,
    class: "",
  },
  {
    name: "Webpack",
    image: "webpack-icon.svg",
    experience: true,
    class: "",
  },
  {
    name: "SEO",
    image: "seo.svg",
    experience: false,
    class: "",
  },
  {
    name: "Woocommerce",
    image: "woocommerce.svg",
    experience: true,
    class: "",
  },
  {
    name: "Mailchimp",
    image: "mailchimp-logo.svg",
    experience: false,
    class: "white",
  },
];
