import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function FeaturedProjects(props) {
  const data = useStaticQuery(graphql`
    {
      allProjectsJson(filter: { featured: { eq: "true" } }) {
        nodes {
          color
          label
          link
          name
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
        }
      }
    }
  `);
  //console.log(data);
  const works = data.allProjectsJson.nodes;
  return (
    <div className="project-section mx-auto mb-10 overflow-hidden relative z-10">
      <div className="text-center xl:w-3/5 mx-auto">
        <h2 className="font-black text-3xl md:text-4xl mb-4 dark:text-white font-extrabold">
          Some of my work
        </h2>
        <p className="text-lg sm:text-xl md:text-1xl text-gray-500 mb-10 lg:px-2 w-4/5 mx-auto">
          I care about code quality and building truly pleasant user
          experiences, with attention to small but important details.
        </p>
      </div>
      <div className="mt-5 grid grid-flow-col xl:w-4/5	grid-cols-1 lg:grid-cols-2 grid-rows-4 lg:grid-rows-2 gap-8 mb-5 mx-5 sm:mx-auto lg:px-10">
        {works.map((work, x) => (
          <div
            key={x}
            className="projectfeauted_work flex flex-col sm:flex-row rounded w-100 p-4 sm:p-10 h-80 overflow-hidden shadow-lg"
            style={{ background: work.color }}
          >
            <div className="flex-col sm:w-3/5 text-white h-100 flex items-start justify-center relative z-10">
              <span className="bg-gray-200 bg-opacity-20 px-2 py-1 font-medium text-xs rounded mb-4 inline-block upppercase">
                {work.label}
              </span>
              <h3 className="py-5 text-2xl sm:text-3xl font-extrabold sm:pr-1 sm:leading-10">
                {work.name}
              </h3>
              {work.link && (
                <a
                  href={`${work.link}`}
                  target="_blank"
                  className="flex items-center p-2 rounded hover:bg-gray-200 hover:bg-opacity-20 mt-2"
                  rel="noreferrer noopener"
                >
                  <svg
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.706 13.707l6.293-6.293V11h2V4h-7v2h3.585l-6.293 6.293 1.415 1.414z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M19.002 18v-4h-2v4h-11V7h4V5h-4a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span className="ml-2 text-xs font-medium">View Website</span>
                </a>
              )}
            </div>
            <div className="flex-col w-3/5 relative featured-work">
              <GatsbyImage
                image={getImage(work.thumbnail)}
                className="top-20 -right-3 absolute w-10/12 absolute z-20 rounded origin-center transform rotate-6 scale-150"
                alt={work.name}
                title={work.name}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-15 xl:w-4/5	mb-5 mx-5 sm:mx-auto lg:px-10">
        <p className="font-bold text-2xl dark:text-white">Want to see more?</p>
        <p className="font-normal text-gray-500 dark:text-gray-400">
          Check out my{" "}
          <Link
            to="/works"
            className="underline text-purple-900 hover:text-blue-400 dark:text-pink-400 dark:hover:text-pink-800"
          >
            Works
          </Link>{" "}
          to see more websites,projects and things I’m currently working on.
        </p>
      </div>
    </div>
  );
}
