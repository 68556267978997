import * as React from "react";
import { graphql } from "gatsby";
import Skills from "../components/Sections/Skills";
import FeaturedProjects from "../components/Sections/FeaturedProjects";
import Hero from "../components/Sections/Hero";
import FeaturedServices from "../components/Sections/FeaturedServices";
import SEO from "../components/Layout/SEO";

const HomePage = ({ data }) => {
  //console.log(data);
  const allSVG = data.allFile.nodes;
  return (
    <>
      <SEO title = "Ina Hranova"/>
      <Hero />
      <FeaturedProjects />
      <FeaturedServices />
      <Skills images={allSVG} />
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: { extension: { eq: "svg" } }) {
      nodes {
        publicURL
      }
    }
  }
`;

export default HomePage;
