import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
export function Hero() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "logos" } }) {
        edges {
          node {
            childrenImageSharp {
              fixed(width: 100) {
                srcWebp
              }
            }
          }
        }
      }
    }
  `);

  const logos = data.allFile.edges;
  //console.log(logos);
  return (
    <>
      <div className="hero-section relative z-10 w-4/5 xl:w-3/5 mx-auto">
        <h1 className="text-center text-3xl lg:text-5xl font-bold py-10 lg:pt-20 dark:text-white ;lg:leading-normal tracking-wide relative z-10">
          <span className="wave animate-wave inline-block text-sm lg:text-3xl relative -top-2">
            👋
          </span>{" "}
          I'm{" "}
          <b className="font-bold lg:text-6xl text-blue-400 dark:text-pink-400">
            Ina
          </b>
          , Freelance Front-End developer & WordPress developer
        </h1>
        <p className="hidden sm:block text-lg sm:text-xl md:text-1xl text-gray-600 dark:text-gray-200 mb-6 my-5 text-center xl:w-3/5 mx-auto relative z-30">
          I help people and brands reach their goals by building user-centric
          digital products and interactive experiences
        </p>
        <div className="flex justify-center flex-col items-center pt-2 relative -top-16">
          <div className="circle  bg-purple-400 dark:bg-purple-900 block rounded-full filter blur-3xl opacity-60 relative top-30"></div>
          <StaticImage
            src="../../images/ina-hranova.png"
            width={600}
            placeholder="blurred"
            alt="Ina Hranova | Freelance Front-End Developer in Bulgaria"
            className="img-hero"
          />
          <div className="ball animate-movedown w-3 h-3 bg-blue-700 dark:bg-gray-100 rounded-full absolute left-10 top-44 z-10 scale-75 transform"></div>
          <div className="ball animate-moveleft w-3 h-3 bg-blue-900 dark:bg-gray-600  rounded-full absolute right-60 top-30 z-10 scale-75 transform "></div>
          <div className="ball animate-moveright w-3 h-3 bg-blue-400 dark:bg-pink-600  rounded-full absolute right-60 top-64 z-10"></div>
          <div className="ball animate-moveup w-3 h-3 bg-blue-900 dark:bg-pink-400  rounded-full absolute right-50 top-20 z-10 scale-75 transform"></div>
          <div className="w-96 h-96	 bg-purple-400 dark:bg-purple-900 block rounded-full filter blur-3xl opacity-80 absolute left-20 bottom-0 z-10"></div>
          <div className="w-96 h-96	 bg-purple-400 dark:bg-purple-900 block rounded-full filter blur-3xl opacity-80 absolute right-20 bottom-0 z-10"></div>
          <div className="lines flex justify-center flex-col items-center absolute bottom-10 transform scale-150 z-0 opacity-20">
            <div className="line border w-200 h-200 border-gray-600 dark:border-gray-300 rounded-full absolute bottom-0"></div>
            <div className="line border w-140 h-140 border-gray-600 dark:border-gray-300 rounded-full absolute bottom-10"></div>
          </div>
          <div className="experience">
            <div className="label text-center bg-gray-50 dark:bg-gray-700 rounded-full block px-5 py-3 absolute -left-5 sm:left-0 z-40 bottom-2/4 shadow-xl">
              <span className="font-semibold block dark:text-white">
                Front-End Developer
              </span>
              <span className="text-gray-500 font-medium text-xs block dark:text-gray-300">
                7+ Years
              </span>
            </div>
            <div className="label text-center bg-gray-50 dark:bg-gray-700 rounded-full block px-5 py-3 absolute -right-5 sm:right-0 z-30 bottom-30 sm:top-auto sm:bottom-1/4 shadow-xl">
              <span className="font-semibold block dark:text-white">
                React(Native) Developer
              </span>
              <span className="text-gray-500 font-medium text-xs block dark:text-gray-300">
                4+ Years
              </span>
            </div>
            <div className="label text-center bg-gray-50 dark:bg-gray-700 rounded-full block px-5 py-3 absolute -right-5 sm:right-0 sm:z-30 bottom-3/4 shadow-xl">
              <span className="font-semibold	 block dark:text-white">
                WordPress Developer
              </span>
              <span className="text-gray-500 font-medium text-xs block dark:text-gray-300">
                7+ Years
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 sm:shadow-lg block sm:rounded overflow-hidden relative z-20 lg:w-4/5 xl:w-3/5 mx-auto -top-60 p-10 -mb-40">
        <h2 className="text-center text-3xl dark:text-white font-semibold	">
          At agency side, client side or as freelancer
        </h2>
        <div className="agencies flex gap-5 md:gap-10 flex-wrap items-center justify-center">
          {logos.map((logo, x) => (
            <img
              loading="lazy"
              src={logo.node.childrenImageSharp[0].fixed.srcWebp}
              height="80"
              width="100"
              key={x}
              alt="Agency"
              className="grayscale filter opacity-80 dark:grayscale-0"
            />
          ))}
        </div>
        <p className="text-center text-gray-500 mt-5 md:mt-0 dark:text-gray-400">
          Hired by creative, strategic and digital agencies in USA and EU, like{" "}
          <a
            href="https://agmastudio.com"
            target="_blank"
            rel="noreferrer noopener"
            className="underline text-gray-600 dark:text-gray-300 font-medium hover:text-gray-700"
          >
            AGMA Studio
          </a>{" "}
          ,{" "}
          <a
            href="https://atlantis-labs.net"
            target="_blank"
            rel="noreferrer noopener"
            className="underline text-gray-600 dark:text-gray-300 font-medium hover:text-gray-700"
          >
            Atlantis Labs
          </a>{" "}
          ,{" "}
          <a
            href="https://hop.online"
            target="_blank"
            rel="noreferrer noopener"
            className="underline text-gray-600 dark:text-gray-300 font-medium hover:text-gray-700"
          >
            Hop Online
          </a>{" "}
          and ect.
        </p>
      </div>
    </>
  );
}

export default Hero;
