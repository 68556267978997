import React, { useState } from "react";
import { skills } from "../../data/skills";
import renderHTMLToReact from "../../utils/html";

export default function Skills(props) {
  const [enabled, setEnabled] = useState(true);
  const clicked = () => {
    setEnabled(!enabled);
  };
  return (
    <div className="skills-section xl:w-3/5 mx-auto mb-5 mt-10 relative z-10">
      <div className="text-center">
        <h2 className="font-black text-3xl md:text-4xl mb-4 dark:text-white">
          Skills & Tools
        </h2>
        <p className="text-lg sm:text-xl md:text-1xl text-gray-500 mb-6 mx-5 sm:mx-auto">
          For those who know what they’re looking for..
        </p>
      </div>
      <div className="flex mt-5">
        <div
          className={`toggle-button rounded-full inline-block mb-8 mx-auto bg-gray-200 dark:bg-gray-800 relative ${
            enabled ? "left" : "right"
          }`}
        >
          <button
            className={`outline-none transition-colors font-medium px-7 py-4 relative text-md z-20 ${
              enabled ? "text-white" : "text-gray-500 dark:text-white"
            } text-center w-28`}
            onClick={clicked}
          >
            Tech
          </button>
          <button
            className={`outline-none transition-colors font-medium px-7 py-4 relative text-md z-20 ${
              !enabled ? "text-white" : "text-gray-500 dark:text-white"
            } text-center w-28`}
            onClick={clicked}
          >
            Tools
          </button>
        </div>
      </div>
      <ul
        className={`flex flex-wrap items-center justify-center lg:mx-3${
          !enabled ? "loaded" : ""
        }`}
      >
        {skills.map((skill, x) => (
          <li
            key={x}
            className={`dark:bg-gray-800 p-4 rounded-lg bg-white flex flex-col items-center justify-center m-2 shadow w-32 h-32 border-gray-100 d-card ${
              enabled === skill.experience ? "showed" : "hide"
            }`}
          >
            <img
              src={
                props.images.find((x) => x.publicURL.includes(skill.image))
                  .publicURL
              }
              width="50"
              height="50"
              loading="lazy"
              alt={skill.name}
              title = {skill.name}
              className={skill.class}
            />
            <span className="block mt-4 mb-1 text-sm font-medium text-gray-600 dark:text-gray-200">
              {skill.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
