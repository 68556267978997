import React from "react";
//https://inakisoria.com/
//https://bit.dev/akiran/react-slick/slider?example=5cc707acc7e7de0015a7db93
//https://github.com/leandrowd/react-responsive-carousel
//https://swiperjs.com/react

import { useStaticQuery, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
// swiper core styles
import "swiper/css/bundle";
import "swiper/css";

export function FeaturedServices() {
  const data = useStaticQuery(graphql`
    {
      allServicesJson {
        nodes {
          description
          name
          thumbnail {
            publicURL
          }
        }
      }
    }
  `);
  //console.log(data);
  const services = data.allServicesJson.nodes;
  return (
    <>
      <div className="relative z-10 xl:w-4/5 lg:px-10 mx-5 sm:mx-auto">
        <h2 className="text-3xl sm:text-4xl mb-4 sm:mb-4 dark:text-white font-extrabold">
          Ways I can help you
        </h2>
      </div>
      <div className="all-services relative z-10 md:-mr-10 md:-ml-10 lg:m-0">
        <Swiper
          spaceBetween={30}
          slidesPerView={"auto"}
          //onSlideChange={() => console.log("slide change")}
          //onSwiper={(swiper) => console.log(swiper)}
          grabCursor={true}
        >
          {services.map((service, x) => (
            <SwiperSlide
              key={x}
              className="shadow-lg md:shadow-2xl px-10 py-14 bg-white dark:bg-gray-800 my-5 px-30 flex flex-col rounded  mb-5 sm:mb-20"
            >
              <img
                src={service.thumbnail.publicURL}
                loading="lazy"
                width="50"
                height="50"
                title={service.name}
                alt={service.name}
              />
              <h3 className="text-2xl my-5 dark:text-white xl:h-16 flex font-medium justify-start items-center">
                {service.name}
              </h3>
              <p className="dark:text-gray-300 opacity-90 text-lg">
                {service.description}
              </p>
            </SwiperSlide>
          ))}
          <SwiperSlide></SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default FeaturedServices;
